import React, { useEffect, useState } from 'react';

import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const p1 = 'a11yford';
const p2 = 'mai';
let p3 = '';

const ContactPage = () => {
  const [showEmail, setShowEmail] = useState(false);
  const [e, setE] = useState('');

  useEffect(() => {
    p3 = 't';
    window.setTimeout(() => {
      const newE = <code>Loading email - please wait 5 secs...</code>;
      setE(newE);
    }, 1001);

    window.setTimeout(() => {
      const newE = (
        <code>
          {p1}&#101;vs&#64;je&#116;e{p2}l&#46;n&#101;{p3}
        </code>
      );
      setE(newE);
    }, 3001);
  }, [showEmail]);

  return (
    <Layout location={{ pathname: '/404' }} config={{}}>
      <Helmet title={'Contact AccessibilityForDevelopers.com'} />
      <div class="layout__content">
        <h2>Contact</h2>

        <p>Get in touch with me!</p>

        <p>
          This is a side project. I think its really important to improve the
          world's accessibility - especially as it isn't often even that hard
          and it can have a big impact on people's lives.
        </p>

        {!showEmail && (
          <button className="btn" onClick={() => setShowEmail(true)}>
            Click here to show contact details
          </button>
        )}

        {showEmail && (
          <>
            {e && <p>Email at {e}</p>}
            {!e && <p>Please wait loading contact details...</p>}
          </>
        )}

        <p className="my-10">
          You can also find me on twitter:{' '}
          <a href="https://twitter.com/A11yForDevs" target="_blank">
            https://twitter.com/A11yForDevs
          </a>
        </p>

        <form className="my-10" name="contact2" data-netlify={true}>
          <h2>Or contact me via this form</h2>
          <input type="hidden" name="form-name" value="contact2" />
          <p>
            <label>
              Name <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              Email <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              Message <textarea name="message" />
            </label>
          </p>
          <p>
            <button class="btn" type="submit">
              Send
            </button>
          </p>
        </form>
      </div>
    </Layout>
  );
};

export default ContactPage;
