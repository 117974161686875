import React from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const NotFoundPage = () => (
  <Layout location={{ pathname: '/404' }} config={{}}>
    <Helmet title={'Error page not found'} />
    <h1>Page not found</h1>
    <p>Page not found - please go to our homepage and look for it there.</p>
    <code>error 404</code>
  </Layout>
);

export default NotFoundPage;
