import { ucwords } from './ucwords';

const mapping = {
  issues: {
    title: 'A11y issues',
    description:
      'Articles and tips about specific accessibility issues and how to resolve them',
  },
};

export function toTag(tag) {
  if (mapping[tag]?.title) {
    return mapping[tag].title;
  }
  return ucwords(tag);
}

export function toTagDescription(tag) {
  if (mapping[tag]?.description) {
    return mapping[tag].description;
  }
  return '';
}
