import React, { useState } from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

enum Category {
  basics = 'Basics',
  content = 'Content',
  html = 'HTML',
  touch = 'Touch',
  mobile = 'Mobile',
}

enum WhoImplements {
  developers = 'Developers',
  designers = 'Designers',
  contentWriters = 'Content Writers',
}

// type Category = 'html' | 'basics'
enum Impact {
  'low' = 'Low',
  'medium' = 'Medium',
  'high' = 'High',
}

interface Item {
  id: string;
  title: string;
  isKeyItem?: boolean;
  categories?: Category[];
  whoImplements?: WhoImplements[];
  whatHtmlElements?: string[];
  good?: Array<{ code: string; explaination?: string }>;
  bad?: Array<{ code: string; explaination?: string }>;
  // 10 - dead easy
  easeOutOfTen?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  impact?: Impact;
  wcag?: Array<{
    name: string;
    level: 'A' | 'AA' | 'AAA';
    url: string;
  }>;
  description: JSX.Element;
  link?: {
    url: string;
    anchor: string;
  };
}

const items: Readonly<Readonly<Item>[]> = [
  {
    id: 'alt',
    title: 'Add alt tag',
    isKeyItem: true,
    categories: [Category.basics, Category.html],
    whoImplements: [WhoImplements.developers],
    easeOutOfTen: 9,
    whatHtmlElements: ['img'],
    impact: Impact.high,
    description: (
      <p>
        Alt text is a text description of images, so that people who cannot see
        images (or those who disable images) can still understand what is inside
        the image/photo.
      </p>
    ),
    link: {
      url: '/avoid-text-in-images/',
      anchor: 'alt text',
    },
  },
  {
    id: 'no-horizontal-scroll',
    title: 'No horizontal scrolling',
    isKeyItem: false,
    categories: [Category.html, Category.touch, Category.mobile],
    whoImplements: [WhoImplements.developers],
    easeOutOfTen: 5,
    // whatHtmlElements: ['img'],
    impact: Impact.low,
    description: (
      <p>
        You should avoid scrolling in two directions. In the majority of cases
        this means no horizontal scrolling.
      </p>
    ),
    // link: {
    //   url: '/avoid-text-in-images/',
    //   anchor: 'alt text',
    // },
    wcag: [
      {
        name: '1.4.10 Reflow',
        level: 'AAA',
        url: 'https://www.w3.org/WAI/WCAG21/Understanding/reflow.html',
      },
    ],
  },
  {
    id: 'descriptive-buttons-links',
    title: 'Buttons and links should have descriptive text',
    description: (
      <p>
        Buttons and links should have useful and descriptive texts - not{' '}
        <em>click here</em>, but something like{' '}
        <em>read our terms and conditions</em>
      </p>
    ),
    whatHtmlElements: ['button', 'a'],
    good: [{ code: '<a href="shop">View our shop</a>' }],
    bad: [
      {
        code: '<a href="shop">Click here</a>',
        explaination: 'The anchor text of "click here" is not descriptive',
      },
      {
        code: '<button>Click here</button>',
        explaination: 'The button text of "click here" is not descriptive',
      },
    ],
  },
  {
    id: 'use-high-contrast',
    title: 'Use high contrast to make your content readable',
    description: (
      <p>
        Text should be easy to read - and an important part of that is to have
        high contrast. Make sure you have text with contrasting colours, so
        people can easily read it.
      </p>
    ),
    link: { url: '/use-high-contrast/', anchor: 'high contrast' },
  },
  {
    id: 'keyboard',
    title: 'Ensure keyboard users can use your site',
    description: (
      <p>
        Remember that not everyone uses a mouse and keyboard! A great way to
        make sure your site is accessible even if people use different input
        devices (such as a screen reader) is to simply make sure you can use the
        site with just a keyboard.
      </p>
    ),
    link: {
      url: '/support-keyboard-only-access/',
      anchor: 'using only a keyboard',
    },
  },
  {
    id: 'focus-hover-states',
    title: 'Show focus and hover states',
    description: (
      <p>
        When a user hovers over an element (with a mouse for example), or an
        element is focused (such as tabbing to a link) then you should indicate
        that visually to the user.
      </p>
    ),
    link: {
      url: '/clear-focus-and-hover-states/',
      anchor: 'clear focus and hover states',
    },
  },
];

if (false) {
  // check not duplicated
  const seen = new Set();
  items.forEach(({ id }) => {
    if (!id) {
      throw new Error('missing id');
    }

    if (seen.has(id)) {
      throw new Error(id);
    }

    seen.add(id);
  });
}

const initialState = Object.fromEntries(
  items.map(item => [item.id, { isChecked: false }])
);

const Checklist = () => {
  const [checked, setChecked] = useState<
    Record<string, { isChecked: boolean }>
  >(initialState);

  console.log(checked);
  function toggle(key) {
    console.log(key);
    setChecked(original => {
      const newState = {
        ...original,
        [key]: { ...original[key], isChecked: !original[key].isChecked },
      };
      console.log(newState);
      return newState;
    });
  }

  return (
    <Layout includeSidebar={false} location={{ pathname: '/404' }} config={{}}>
      <Helmet title={'Accessibility Checklist'} />
      <div className="layout__content layout__content--full-width">
        <h2>Web Accessibility Checklist</h2>

        {/*<div className="flex flex-wrap w-full">*/}
        {/*  <div className="w-full lg:w-1/4 mb-4 bg-gray-500">coming</div>*/}
        {/*  <div className="w-full lg:w-3/4 mb-4 bg-gray-400">soon</div>*/}
        {/*</div>*/}

        <p>
          This is a checklist of the most common accessibility issues that you
          should initially spend attention on. It is aimed at someone who wants
          to go over the basics. This list is not comprehensive, and it is
          recommended to do more than just go through a checklist. But this is a
          nice start of things to make sure you check when making your site or
          application accessible.{' '}
          <b>This is still work in progress (July 2022)</b>
        </p>

        <form>
          {items.map(question => (
            <div className="card" key={question.id}>
              <h3>{question.title}</h3>

              {/*<label>*/}
              {/*  Done?{' '}*/}
              {/*  <input*/}
              {/*    type="checkbox"*/}
              {/*    checked={checked[question.id]?.isChecked}*/}
              {/*    onClick={() => toggle(question.id)}*/}
              {/*  />*/}
              {/*</label>*/}

              {/*{question.impact !== undefined && (*/}
              {/*  <p>Impact: {question.impact}</p>*/}
              {/*)}*/}
              {/*{question?.whoImplements?.length && (*/}
              {/*  <p>Who? {question.whoImplements.join(', ')}</p>*/}
              {/*)}*/}

              {question.whatHtmlElements &&
                question.whatHtmlElements?.length > 0 && (
                  <p>
                    What HTML elements?{' '}
                    {question.whatHtmlElements
                      .map(el => `<${el}>`)
                      .map(e => (
                        <span>
                          {' '}
                          <code>{e}</code>{' '}
                        </span>
                      ))}
                  </p>
                )}

              <div>{question.description}</div>
              {question.link && (
                <Link to={question.link.url}>
                  View more about {question.link.anchor}
                </Link>
              )}

              {question.good?.length && (
                <>
                  <h4>
                    Good {question.bad?.length === 1 ? 'example' : 'examples'}
                  </h4>

                  {question.good?.map(s => (
                    <div>
                      <code>{s.code}</code>
                      {s.explaination && (
                        <p>
                          Reason that this is a good example: {s.explaination}
                        </p>
                      )}
                    </div>
                  ))}
                </>
              )}
              {question.bad?.length && (
                <>
                  <h4>
                    Bad {question.bad?.length === 1 ? 'example' : 'examples'}
                  </h4>
                  <p>Do not do these!</p>
                  {question.bad?.map(s => (
                    <div className="card">
                      <code>{s.code}</code>
                      {s.explaination && (
                        <p>
                          Reason that this is a bad example: {s.explaination}
                        </p>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </form>
      </div>
    </Layout>
  );
};

export default Checklist;
