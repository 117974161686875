import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Layout from '../components/Layout';
import { toTag } from '../utils/toTag';

class BlogPostTemplate extends React.Component {
  render() {
    // console.log(this.props);
    const post = this.props.data.markdownRemark;
    // console.log({post});
    const siteTitle = get(this.props, `data.config.frontmatter.title`);
    // const tags = get(this.props, `data.config.frontmatter`);
    // console.log(tags);
    const siteBio = get(this, 'props.data.config.html');
    const siteDescription = post.excerpt;
    const { previous, next } = this.props.pageContext;

    const moreLinks = [];

    if (previous) {
      moreLinks.push(previous);
    }
    if (next) {
      moreLinks.push(next);
    }
    // console.log(moreLinks);

    return (
      <Layout
        location={this.props.location}
        config={this.props.data.config}
        translations={post.frontmatter.translations}
      >
        <Helmet
          htmlAttributes={{ lang: this.props.pageContext.language }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        />
        <div
          className="

bg-white
pt-2
pb-2
mb-2
                    border-b-2 border-gray-300;

        "
        >
          <h2 className="h2">Accessibility Tip: {post.frontmatter.title}</h2>
          <p
            className="text-sm mb-1  my-1 mt-2
            uppercase


            "
          >
            Last updated on {post.frontmatter.date}
            {post.frontmatter.tags?.length && (
              <span className="px-4 normal-case">
                {post.frontmatter.tags?.map(tag => (
                  <Link to={'/category/' + tag} key={tag}>&bull; {toTag(tag)}</Link>
                ))}
              </span>
            )}
          </p>
        </div>
        <div
          className="layout__content layout__content--post mt-8"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

        <section
          className="
            border-slate-200
            border-t
            mt-20
            pt-10
            bg-slate-300
            p-6
          layout__content
          text-xl
          max-w-prose
            "
        >
          <h2
            className="
          font-semibold
          mb-6
          mt-0
          p-0
          text-slate-900
          "
          >
            Found this post useful?
          </h2>

          <p>
            Please consider sharing this link with your work colleagues or on
            social media. There are no ads on my site, I just want to promote
            accessibility.
          </p>

          <p>
            <b>Found an issue?</b> please point them out - let me know if there
            is a mistake and I'll update it
          </p>

          <p>Follow me on Twitter: <a target="_blank" href="https://twitter.com/A11yForDevs">@A11yForDevs</a>. I post links to interesting a11y articles and resources.</p>
        </section>

        {/*<hr*/}
        {/*  className="mb-2"*/}
        {/*/>*/}
        {/*<Bio>*/}
        {/*  <div dangerouslySetInnerHTML={{ __html: siteBio }} />*/}
        {/*</Bio>*/}

        <section
          className="
            border-slate-200
            border-t
            mt-20
            pt-5
            px-5
            "
        >
          <h2
            className="
          font-semibold
          mb-6
          text-slate-900
          "
          >
            More posts
          </h2>
          <div
            className="
          gap-x-8
          gap-y-10
          grid
          grid-cols-1
          lg:grid-cols-2
          "
          >
            {moreLinks.map(l => {
              return (
                <article
                  className="flex flex-col items-start"
                  key={l.fields.slug}
                >
                  <h3 className=" text-lg text-slate-900 font-semibold ">
                    <Link to={l.fields.slug}>{l.frontmatter.title}</Link>
                  </h3>
                  <p className="text-sm">{l.excerpt}</p>
                  <time
                    dateTime="2022-05-27T15:00:00.000Z"
                    className="
                          text-xs
                          leading-7
                          uppercase
                          "
                  >
                    May 27, 2022
                  </time>
                  <Link
                    className="
                        bg-slate-100
                        focus:outline-none
                        focus:ring-2
                        focus:ring-slate-500
                        font-bold
                        h-10
                        hover:bg-slate-200
                        hover:text-slate-900
                        no-underline
                        hover:no-underline
                        inline-flex
                        items-center
                        mt-1
                        mb-3
                        order-1
                        px-3
                        rounded-lg
                        border
                        border-slate-200
                        text-slate-700
                        text-sm
                        whitespace-nowrap
                        "
                    to={l.fields.slug}
                  >
                    Read more
                    <span className="sr-only">, {l.frontmatter.title}</span>
                  </Link>
                </article>
              );
            })}
          </div>
        </section>

        {/*<ul*/}
        {/*  style={{*/}
        {/*    display: 'flex',*/}
        {/*    flexWrap: 'wrap',*/}
        {/*    justifyContent: 'space-between',*/}
        {/*    listStyle: 'none',*/}
        {/*    padding: 0,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <li>*/}
        {/*    {*/}
        {/*      previous &&*/}
        {/*      <Link to={previous.fields.slug} rel="prev">*/}
        {/*        ← {previous.frontmatter.title}*/}
        {/*      </Link>*/}
        {/*    }*/}
        {/*  </li>*/}
        {/*  <li>*/}
        {/*    {*/}
        {/*      next &&*/}
        {/*      <Link to={next.fields.slug} rel="next">*/}
        {/*        {next.frontmatter.title} →*/}
        {/*      </Link>*/}
        {/*    }*/}
        {/*  </li>*/}
        {/*</ul>*/}
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $language: String!) {
    config: markdownRemark(
      frontmatter: { language: { eq: $language }, type: { eq: "language" } }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        language
        tags
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tags
      }
    }
  }
`;
