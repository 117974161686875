import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { rhythm } from '../utils/typography';
import { toTag, toTagDescription } from '../utils/toTag';

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMarkdownRemark;
  const desc = toTagDescription(tag);
  // const tagHeader = `${totalCount} post${
  //     totalCount === 1 ? '' : 's'
  // } tagged with "${tag}"`;
  return (
    <Layout location="/test-123" config={{}}>
      <Helmet
        htmlAttributes={{ lang: pageContext?.language ?? 'en' }}
        meta={[
          {
            name: 'description',
            content: 'All accessibility posts about ' + tag,
          },
        ]}
        title={'Accessibility posts about ' + tag + (desc ? '. ' + desc : '')}
      />
      <div className="">
        <h2 className="h3">{toTag(tag)}</h2>

        {desc && <p className="text-lg my-3">{desc}</p>}

        {/*<ul>*/}
        {edges.map(({ node }) => {
          const { slug } = node.fields;
          const { title } = node.frontmatter;

          return (
            <div key={node.fields.slug} className="m-5 mb-10 border p-4">
              <h3 className="m-0 ">
                <Link className="bold mb-3 text-xl" to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small className="block mb-4 text-xs">
                {node.frontmatter.date}
              </small>
              <p
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
                className="max-w-prose"
              />
            </div>
          );
          return (
            <li key={slug}>
              Post: <Link to={slug}>{title}</Link>
            </li>
          );
        })}
        {/*</ul>*/}
        {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
        <section className="block border-t py-4">
          <p className="my-3">
            Want to see more? This is just a list of posts in my {tag} category.
            You can find more elsewhere on this site.
          </p>
          <Link to="/category/" className="btn ">
            See all categories
          </Link>
        </section>
      </div>
    </Layout>
  );
};
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            excerpt: PropTypes.string.isRequired,
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
};
export default Tags;
export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "LL")
          }
        }
      }
    }
  }
`;
