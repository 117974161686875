import React from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

const words = [
  {
    word: 'a11y',
    definition: (
      <div>
        a11y is the shortened version of <code>accessibility</code>. There are
        11 letters between the 'a' and 'y' ... so a11y
      </div>
    ),
  },
  { word: 'Access keys', definition: <div>
      Access keys are when you can assign keyboard shortcuts to HTML elements. For example
      <code>{'<div accessKey="h" />'}</code>.
      This often causes accessibility issues if they conflict with default or expected keyboard shortcuts. However they can be useful for advanced users of your app/site with assistive technology - if they learn what they are.
    </div>},
  {
    word: 'Accessibility',
    definition: (
      <>
        Accessibility is making sites/apps useable by everyone - even if they
        have disabilities
      </>
    ),
  },
  {
    word: 'ARIA',
    definition: (
      <>
        Web Accessibility Initiative's – Accessible Rich Internet Applications.
        This is a set of standards used to tell screen readers and other
        assistive technology about what elements on the page are.
      </>
    ),
    link: '/what-is-wcag-guidelines',
  },
  {
    word: 'Aria live',
    definition: (
      <>
        The <code>aria-live</code> attribute can be used to tell screen readers
        how to announce changes to the content. For example, if you had an alert
        you wanted the screen reader to announce. The default for most elements
        is <code>aria-live='off'</code> which means changes will not be
        announced.
      </>
    ),
    link: '/what-is-wcag-guidelines',
  },
  {
    word: 'Assistive Technology',
    definition:
      'Assistive Tech is the hardware and software that can be used to aid with accessibility issues. For example Mac OS has Voiceover (screen reader) and magnifier (to zoom in parts of the screen).',
  },

  {
    word: 'Hover state',
    definition:
      'Hover state is when your mouse hovers over an interactive element (such as a button or link) and it changes how it looks (e.g. has an underline). This is similar to the focus state but only active when the mouse is on top of the element. By default there may be no hover state so it is important that this is set up in the CSS.',
  },
  {
    word: 'Focus state',
    definition: (
      <p>
        Focus state is the change of how an element (such as button or link)
        looks when it is focused. An element is focused after being clicked or
        tabbed to. You can see the current focused element by looking at{' '}
        <code>document.activeElement</code> in JS.
      </p>
    ),
  },
  {
    word: 'Tabbing/tab to',
    definition:
      <p>When the <code>tab</code> button (normally left of the <code>q</code> button) is pressed, it will go to the next interactive element (links, buttons etc). It is critical that the focus state is set up so you know what item is currently selected.</p>
  },
  {
    word: 'H1 tags, heading tags',
    definition: (
      <>
        The heading tags (H1, H2, H3, H4, H5, H6) are headings. H1 is meant to
        be the biggest, main heading on the page, and H6 is the smallest
      </>
    ),
  },
  {
    word: 'JAWS',
    definition: (
      <>JAWS is Job Access WithSpeech - a very commonly used screen reader.</>
    ),
  },
  {
    word: 'Screen reader',
    definition: (
      <>
        A screen reader is an assistive technology that can take content that is
        usually displayed visually, and describe it by reading out the words
        (and description of the type of content)
      </>
    ),
  },
  {
    word: 'WCAG',
    definition: (
      <>
        Web content accessibility guidelines - a set of rules to follow to make
        content accessible
      </>
    ),
    link: '/what-is-wcag-guidelines',
  },
  {
    word: 'UI',
    definition: <>User interface - the design/look</>,
  },
  {
    word: 'UX',
    definition: <>User experience - how the user interacts with your site</>,
  },
].sort((a, b) => a.word.toLowerCase().localeCompare(b.word.toLowerCase()));

const Glossary = () => (
  <Layout location={{ pathname: '/a11y-glossary' }} config={{}}>
    <Helmet title={'Accessibility Glossary | Accessibility For Developers'} />
    <div class="layout__content">
      <h2>Web Accessibility Glossary</h2>

      <dl>
        {words.map(word => (
          <div
            key={word.word}
            id={`word-${word.word}`}
            style={{ position: 'relative' }}
            className="mb-8"
          >
            <dt>
              <a
                href={'#word-' + word.word.toLowerCase()}
                aria-label={word.word + ' permalink'}
                className="anchor before"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  height="16"
                  version="1.1"
                  viewBox="0 0 16 16"
                  width="16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
                  ></path>
                </svg>
              </a>

              {word.word}
            </dt>
            <dd>
              {word.definition}{' '}
              {word.link && (
                <Link to={word.link}>See more about {word.word}</Link>
              )}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  </Layout>
);

export default Glossary;
