import React, { useState } from 'react';
import clsx from 'clsx';
// import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';

const createNode = (questionText, ifNo, ifYes) => {
  return {
    type: 'node',
    questionText,
    ifNo,
    ifYes,
  };
};

const q4 = createNode(
  'Is the image purely decorative or not intended for the user?',
  'end',
  <div>
    <h4>Use an empty alt attribute. See Decorative Images.</h4>
  </div>
);
const q3 = createNode(
  'Does the image contribute meaning to the current page or context?',
  q4,
  <div>
    <h4>… and it’s a simple graphic or photograph.</h4>
    <p>
      Use a brief description of the image in a way that conveys that meaning in
      the alt attribute. See Informative Images.
    </p>
    <h4>… and it’s a graph or complex piece of information.</h4>
    <p>
      Include the information contained in the image elsewhere on the page. See
      Complex Images.
    </p>
    <h4>… and it shows content that is redundant to real text nearby.</h4>
    <p>Use an empty alt attribute. See (redundant) Functional Images.</p>
  </div>
);
const q2 = createNode(
  'Is the image used in a link or a button, and would it be hard or impossible to understand what the link or the button does, if the image wasn’t there?',
  q3,
  <h1>
    Use the alt attribute to communicate the destination of the link or action
    taken. See Functional Images.
  </h1>
);
const q1 = createNode(
  'Does the image contain text?',
  q2,
  <div>
    <h4>… and the text is also present as real text nearby.</h4>
    <p>Use an empty alt attribute. See Decorative Images.</p>
    <h4>… and the text is only shown for visual effects.</h4>
    <p>Use an empty alt attribute. See Decorative Images.</p>
    <h4>… and the text has a specific function, for example is an icon.</h4>
    <p>
      Use the alt attribute to communicate the function of the image. See
      Functional Images.
    </p>
    <h4>… and the text in the image is not present otherwise.</h4>
    <p>
      Use the alt attribute to include the text of the image. See Images of
      Text.
    </p>
  </div>
);

const qs = [q1, q2, q3, q4];

const AltTree = () => {
  const [currentQIndex, setCurrentQIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [showOutput, setShowOutput] = useState(undefined);
  const currentQ = qs[currentQIndex];

  const reset = () => {
    setCurrentQIndex(0);
    setAnswers([]);
    setShowOutput(undefined);
  };
  console.log({ currentQ, qs, currentQIndex });

  const sayYes = type => {
    console.log('saying ' + type);
    // if(currentQIndex === answers.length) {
    const next = type === 'yes' ? currentQ.ifYes : currentQ.ifNo;

    setAnswers([...answers, type]);
    // console.log({nextYes, currentQ})
    if (next?.type === 'node') {
      setCurrentQIndex(currentQIndex + 1);
      return;
    } else {
      setShowOutput(currentQIndex);
    }
  };

  return (
    <Layout location={{ pathname: '/404' }} config={{}}>
      <Helmet title={'Alt text decision tree - decide if you need alt text'} />
      <div class="layout__content">
        <h2>Alt text decision tree - decide if you need alt text</h2>

        <button className="btn" onClick={reset}>
          Reset
        </button>

        <hr />

        {qs.map((q, index) => {
          if (index > currentQIndex) return null;
          return (
            <div key={q.questionText} className="card">
              <h4 className="m-0 p-0">Question {index + 1}</h4>

              <p>{q.questionText}</p>

              <button
                disabled={index < currentQIndex || showOutput !== undefined}
                className={clsx({
                  btn: true,
                  'btn--selected': answers[index] === 'yes',
                  'btn--not-selected': answers[index] === 'no',
                })}
                onClick={() => sayYes('yes')}
              >
                Yes
              </button>
              <button
                disabled={index < currentQIndex || showOutput !== undefined}
                className={clsx({
                  btn: true,
                  'btn--selected': answers[index] === 'no',
                  'btn--not-selected': answers[index] === 'yes',
                })}
                onClick={() => sayYes('no')}
              >
                No
              </button>

              {answers[index] && (
                <div>
                  <p>You answered: {answers[index]}</p>
                </div>
              )}

              {showOutput === index && (
                <div>
                  <h1></h1>

                  {qs[showOutput].ifYes}
                </div>
              )}
            </div>
          );
        })}

        <h3>Find out more about how to use the alt text for accessibility</h3>

        <p>
          I have an in-depth article about{' '}
          <Link to="/alt-text-for-images-accessibility/">
            using alt text for accessibility
          </Link>{' '}
          that you might find useful.
        </p>
        <h3>Credits</h3>
        <p>
          Main idea is heavily based on
          https://www.w3.org/WAI/about/using-wai-material/{' '}
        </p>
      </div>
    </Layout>
  );
};

export default AltTree;
