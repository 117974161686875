exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-11-y-checklist-tsx": () => import("./../../../src/pages/a11y-checklist.tsx" /* webpackChunkName: "component---src-pages-a-11-y-checklist-tsx" */),
  "component---src-pages-a-11-y-glossary-js": () => import("./../../../src/pages/a11y-glossary.js" /* webpackChunkName: "component---src-pages-a-11-y-glossary-js" */),
  "component---src-pages-a-11-y-tools-alt-text-decsion-tree-js": () => import("./../../../src/pages/a11y-tools/alt-text-decsion-tree.js" /* webpackChunkName: "component---src-pages-a-11-y-tools-alt-text-decsion-tree-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-category-js": () => import("./../../../src/pages/category.js" /* webpackChunkName: "component---src-pages-category-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

