import React from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

const IndexPage = () => (
  <Layout location={{ pathname: '/404' }} config={{}}>
    <Helmet title={'Welcome to AccessibilityForDevelopers.com'} />
    <div class="layout__content">
      <h2>Welcome!</h2>

      <p>Welcome to my site about accessibility.</p>

      <p>
        I'm a web developer who struggled to find good resources for
        accessibility, so decided to make this.
      </p>

      <p>
        This whole project is still under development. Lots of content still to
        add, but little time to add it.
      </p>

      <p>no ads on this site :) </p>

      <p>
        Any questions? Head over to my <Link to="/contact/">contact page</Link>.
      </p>
    </div>
  </Layout>
);

export default IndexPage;
