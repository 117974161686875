import React from 'react';
import Layout from '../components/Layout';
import Helmet from 'react-helmet';

const AboutPAge = () => (
  <Layout location={{ pathname: '/404' }} config={{}}>
    <Helmet title={'About AccessibilityForDevelopers.com'} />
    <div class="layout__content">
      <h2>About this site</h2>

      <p>
        This is a free site, aimed at sharing knowledge/awareness about
        increasing accessibility on websites and apps.
      </p>

      <p>Follow me on Twitter: <a target="_blank" href="https://twitter.com/A11yForDevs">@A11yForDevs</a>. I post links to interesting a11y articles and resources.</p>

      <div className="max-height-ul layout__a11y-example" hidden>
        {/*do not remove me, so css includes this css classname*/}
      </div>
    </div>
  </Layout>
);

export default AboutPAge;
